//Packing/PouchContentDisplay.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const PouchContentDisplay = ({ token, reloadFlag  } ) => {
  const pouchNumber = useSelector((state) => state.user.pouchNumber); 
  const pouch_barcode = useSelector((state) => state.user.pouchBarcode);
  const [pouchContents, setPouchContents] = useState([]);
  const pouch_number = useSelector((state) => state.user.pouchNumber);

  useEffect(() => {
    const fetchPouchContents = async () => {
      try {
        const response = await axios.get(`/api/packed_products_in_carrier_summary?carrier_barcode=${pouch_barcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPouchContents(response.data);
      } catch (error) {
        console.error('Error fetching pouch contents:', error);
      }
    };

    if (pouchNumber) {
      fetchPouchContents();
    } else {
      setPouchContents([]);
    }
  }, [pouchNumber, token, reloadFlag ]);

  const getExpiryStatus = (expiryDate) => {
    const currentDate = new Date();
    const oneMonthLater = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const parsedExpiryDate = new Date(expiryDate);

    if (parsedExpiryDate <= currentDate) {
      return 'expired';
    } else if (parsedExpiryDate <= oneMonthLater) {
      return 'expiringSoon';
    } else {
      return 'ok';
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2>Pouch Contents</h2>
        {pouch_number && <div>Pouch Number: {pouch_number}</div>}
      </div>
      <div className="card-body">
        {pouchContents.length ? (
          <table>
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Amount</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {pouchContents
                .filter(content => content.total_amount !== 0)
                .map((content) => {
                  const expiryStatus = getExpiryStatus(content.Expiry_Date);
                  let bgColor = 'transparent';
                  if (expiryStatus === 'expiringSoon') {
                    bgColor = 'yellow';
                  } else if (expiryStatus === 'expired') {
                    bgColor = 'red';
                  }
                  return (
                    <tr key={content.Unique_ID} style={{ backgroundColor: bgColor }}>
                      <td>{content.Product_Detail}</td>
                      <td>{content.Amount}</td>
                      <td>{new Date(content.Expiry_Date).toLocaleDateString()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <p className="account-card-item">
            <span className="account-card-label">This pouch is empty.</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default PouchContentDisplay;
