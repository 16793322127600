import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const AllPoucheContents = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchDataForAllPoucheContents = async () => {
    const response = await axios.get('api/pouch_contents_all_report/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Carrier Number': item.Carrier_Number || '',
      'Pouch Number': item.Pouch_Number || '',
      'Product Name': item.Product_Name || '',
      'Batch Number': item.Batch_Number || '',
      'Amount': item.amount || ''
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchDataForAllPoucheContents();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'AllPoucheContents');
    XLSX.writeFile(wb, 'AllPoucheContents.xlsx');
  };

  const initialColumns = [
    'Carrier Number', 'Pouch Number', 'Product Name', 'Batch Number', 'Amount'
  ];
  const allColumns = [
    'Carrier Number', 'Pouch Number', 'Product Name', 'Batch Number', 'Amount'
  ];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default AllPoucheContents;