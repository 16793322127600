import React, { useEffect } from 'react';
import Header from '../components/header';
import UserDetails from '../components/Display/UserDetails2';
import { useSelector } from 'react-redux';
import PouchTypesPieChart from '../components/Visulisations/PouchTypesPieChart';
import PouchesPackedByDateChart from '../components/Visulisations/PouchesPackedByDateChart';
import '../static/CSS/HomePage.css'; // Import the CSS file

const token = localStorage.getItem("token");

const HomePage = () => {
  const { username, station, pouchNumber } = useSelector((state) => state.user);

  useEffect(() => {
  }, [username, station, pouchNumber]);

  return (
    <div className='main-content'>
      <Header />
      <UserDetails token={token} /> 
      <div className="charts-container">
        <PouchTypesPieChart 
          token={token} 
          status={'Packed'}         
          title="Pouches Packed"
        />
        <PouchTypesPieChart 
          token={token} 
          status={'QAed'}
          title="Pouches QAed"
        />
        <PouchesPackedByDateChart 
          carrierType='Pouch' 
          carrierTypeDescriptions={['Arrest', 'Fluids', 'Cardiac']}  
          combined={true} 
          token={token}
          title="Total Pouches Packed"
        />
        <PouchesPackedByDateChart 
          carrierType='Carrier' 
          carrierTypeDescriptions={['Create']}  
          combined={true} 
          token={token}
          title="Total Creates Packed"
        />
        <PouchesPackedByDateChart 
          carrierType='Pouch' 
          carrierTypeDescriptions={['Arrest', 'Fluids', 'Cardiac']}  
          combined={false} 
          token={token}
          title="Pouches Packed by Type"
        />
        <PouchesPackedByDateChart 
          carrierType='Medicine_Bag' 
          carrierTypeDescriptions={['Paramedic']}  
          combined={false} 
          token={token}
          title="Medicine Bags Packed by Type"
        />
      </div>
    </div>
  );
};

export default HomePage;