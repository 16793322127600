import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const PouchStatus = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchPouchStatus = async () => {
    const response = await axios.get('api/all_carriers_fully_packed_status/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const formattedData = response.data.map(item => ({
      'Carrier Number': item.Carrier_Number || '',
      'Carrier Type': item.Carrier_Type || '',
      'Fully Packed': item.FullyPacked ? 'Yes' : 'No'
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchPouchStatus();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pouch Status');
    XLSX.writeFile(wb, 'PouchStatus.xlsx');
  };

  const initialColumns = ['Carrier Number', 'Carrier Type', 'Fully Packed'];
  const allColumns = ['Carrier Number', 'Carrier Type', 'Fully Packed'];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default PouchStatus;