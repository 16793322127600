import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableTable from '../Table/Table';
import * as XLSX from 'xlsx';

const PouchMedicineDataStores = ({ token }) => {
  const [data, setData] = useState([]);

  const fetchPouchMedicineDataStores = async () => {
    const response = await axios.get('api/pouch_contents_with_price_report/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    const formattedData = response.data.map(item => ({
      'Carrier Number': item.Carrier_Number || '',
      'Total Price': item.Total_Price || '',
      'Amount': item.Amount || ''
    }));

    setData(formattedData);
  };

  useEffect(() => {
    fetchPouchMedicineDataStores();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pouch Medicine Data Stores');
    XLSX.writeFile(wb, 'PouchMedicineDataStores.xlsx');
  };

  const initialColumns = ['Carrier Number', 'Total Price', 'Amount'];
  const allColumns = ['Carrier Number', 'Total Price', 'Amount'];

  return (
    <div>
      <EditableTable initialColumns={initialColumns} data={data} allColumns={allColumns} />
      <button className='button' onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default PouchMedicineDataStores;