import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

// Register the required components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const PouchTypesPieChart = ({ token, status, title }) => {
    const [chartData, setChartData] = useState(null);

    const colors = {
        'Hypo': 'Green',
        'Arrest': 'Red',
        'Resp': 'Blue',
        'Cardiac': 'Orange',
        'Specialist': 'Yellow',
        'Fluids': 'Black',
        'CFR': 'White'
    };

    const fetchPouchTypesCount = async () => {
        try {
            const response = await axios.get('/api/carrier_history_by_date/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Carrier history by date:', response.data);
            const data = response.data;

            // Filter data to include only Carrier_Type 'Pouch' and the provided status
            const filteredData = data.filter(item => item.Carrier_Type === 'Pouch' && item.Action === status);

            // Count the occurrences of each Carrier_Type_Description
            const counts = filteredData.reduce((acc, item) => {
                const type = item.Carrier_Type_Description;
                if (!acc[type]) {
                    acc[type] = 0;
                }
                acc[type]++;
                return acc;
            }, {});

            // Prepare the data for the pie chart
            const chartLabels = Object.keys(counts);
            const chartValues = Object.values(counts);
            const chartColors = chartLabels.map(label => colors[label] || 'Gray');

            setChartData({
                labels: chartLabels,
                datasets: [{
                    data: chartValues,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1
                }]
            });
        } catch (error) {
            console.error('Error fetching pouch types count:', error);
        }
    };

    useEffect(() => {
        fetchPouchTypesCount();
    }, [status]);

    return (
        <div className="visualization-card">
            <h2 className="visualization-header">{title}</h2>
            <div className="visualization-body">
                {chartData ? <Pie data={chartData} /> : <p>Loading...</p>}
            </div>
        </div>
    );
};

export default PouchTypesPieChart;