import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PouchesPackedByDateChart = ({ token, carrierType, carrierTypeDescriptions, combined, title }) => {
    const [chartData, setChartData] = useState(null);

    const fetchCarrierHistoryByDate = async () => {
        try {
            const response = await axios.get('/api/carrier_history_by_date/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Carrier history by date:', response.data);
            const data = response.data;

            // Filter data based on the provided Carrier_Type and Carrier_Type_Descriptions props
            const filteredData = data.filter(item => 
                item.Carrier_Type === carrierType &&
                carrierTypeDescriptions.includes(item.Carrier_Type_Description)
            );

            // Filter and count the packed items per day
            const packedData = filteredData.filter(item => item.Action === 'Packed');

            let packedCountsByDate = {};
            if (combined) {
                packedCountsByDate = packedData.reduce((acc, item) => {
                    const date = item.DateTime.split('T')[0]; // Extract the date part
                    if (!acc[date]) {
                        acc[date] = 0;
                    }
                    acc[date]++;
                    return acc;
                }, {});
            } else {
                packedCountsByDate = carrierTypeDescriptions.reduce((acc, desc) => {
                    acc[desc] = packedData.filter(item => item.Carrier_Type_Description === desc).reduce((dateAcc, item) => {
                        const date = item.DateTime.split('T')[0]; // Extract the date part
                        if (!dateAcc[date]) {
                            dateAcc[date] = 0;
                        }
                        dateAcc[date]++;
                        return dateAcc;
                    }, {});
                    return acc;
                }, {});
            }

            const labels = combined ? Object.keys(packedCountsByDate) : Object.keys(packedCountsByDate[carrierTypeDescriptions[0]]);
            const colors = ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'];
            const borderColors = ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'];

            const datasets = combined ? [{
                label: 'Pouches Packed',
                data: Object.values(packedCountsByDate),
                backgroundColor: colors[0],
                borderColor: borderColors[0],
                borderWidth: 2,
                fill: false,
                tension: 0.1 // Adds a slight curve to the line
            }] : carrierTypeDescriptions.map((desc, index) => ({
                label: `${desc}`,
                data: Object.values(packedCountsByDate[desc]),
                backgroundColor: colors[index % colors.length],
                borderColor: borderColors[index % borderColors.length],
                borderWidth: 2,
                fill: false,
                tension: 0.1 // Adds a slight curve to the line
            }));

            setChartData({
                labels: labels,
                datasets: datasets
            });
        } catch (error) {
            console.error('Error fetching carrier history by date:', error);
        }
    };

    useEffect(() => {
        fetchCarrierHistoryByDate();
    }, [carrierType, carrierTypeDescriptions, combined]);

    return (
        <div className="visualization-card">
            <h2 className="visualization-header">{title}</h2>
            <div className="visualization-body">
                {chartData ? <Line 
                    data={chartData} 
                    options={{
                        plugins: {
                            legend: {
                                position: 'right'
                            }
                        }
                    }} 
                    className="pie-chart-container" 
                /> : <p>Loading...</p>}
            </div>
        </div>
    );
};

export default PouchesPackedByDateChart;