import React, { useState, useEffect } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import axios from 'axios';

const CarrierContentsForm = ({ token, selectedCarrierType }) => {
  const [carrier, setCarrier] = useState('');
  const [carrierTypeName, setCarrierTypeName] = useState('');
  const [productName, setProductName] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [refillAmount, setRefillAmount] = useState('');
  const [selectedCarrierTypeId, setSelectedCarrierTypeId] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [carrierTypeNames, setCarrierTypeNames] = useState([]);
  const [consumables, setConsumables] = useState([]); 

  useEffect(() => {
    if (selectedCarrierType) {
      setCarrierTypeName(selectedCarrierType['Carrier_type_Name']);
      setProductName(selectedCarrierType['Product_Name']);
      setMaxAmount(selectedCarrierType['Max_amount']);
      setMinAmount(selectedCarrierType['Min_Amount']);
      setRefillAmount(selectedCarrierType['Refill_amount']);
      setSelectedCarrierTypeId(selectedCarrierType.Unique_id);
    }
  }, [selectedCarrierType]);

  useEffect(() => {
    const fetchCarriersAndConsumables = async () => {
      try {
        const [carriersResponse, consumablesResponse] = await Promise.all([
          axios.get('/api/Carrier/', {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
          axios.get('/api/Consumable/', {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
        ]);

        setCarriers(carriersResponse.data);
        setConsumables(consumablesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCarriersAndConsumables();
  }, [token]);

  useEffect(() => {
    const fetchCarrierTypeNames = async () => {
      if (carrier) {
        try {
          const response = await axios.get(`/api/carrier_type_name/?carrier=${carrier}`, {
            headers: { 'Authorization': `Bearer ${token}` },
          });
          setCarrierTypeNames(response.data);
        } catch (error) {
          console.error('Error fetching carrier type names:', error);
        }
      }
    };

    fetchCarrierTypeNames();
  }, [carrier, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/create_carrier_contents/create/', {
        Unique_ID: selectedCarrierTypeId,
        Carrier_type_Name: carrierTypeName,
        Product_Name: productName,
        Max_amount: maxAmount,
        Min_Amount: minAmount,
        Refill_amount: refillAmount,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error creating carrier contents:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/create_carrier_contents/update/${selectedCarrierTypeId}/`, {
        Unique_ID: selectedCarrierTypeId,
        Carrier_type_Name: carrierTypeName,
        Product_Name: productName,
        Max_amount: maxAmount,
        Min_Amount: minAmount,
        Refill_amount: refillAmount,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating carrier contents:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/create_carrier_contents/delete/${selectedCarrierTypeId}/`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error deleting carrier contents:', error);
    }
  };

  const handleRefresh = () => {
    setCarrier('');
    setCarrierTypeName('');
    setProductName('');
    setMaxAmount('');
    setMinAmount('');
    setRefillAmount('');
    setSelectedCarrierTypeId(null);
  };

  const handleDeleteConfirmation = () => {
    if (window.confirm(`Are you sure you want to delete ${productName}?`)) {
      handleDelete();
    }
  };

  const carrierOptions = carriers.map(carrier => ({
    value: carrier.carrier,
    label: carrier.carrier,
  }));

  const carrierTypeNameOptions = carrierTypeNames.map(type => ({
    value: type.carrier_type_name,
    label: type.carrier_type_name,
  }));

  const consumableOptions = consumables.map(consumable => ({
    value: consumable.product_name,
    label: consumable.product_name,
  }));

  const questions = [
    {
      text: 'Carrier',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setCarrier(e.target.value),
      value: carrier,
      options: carrierOptions,
    },
    {
      text: 'Carrier Type Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setCarrierTypeName(e.target.value),
      value: carrierTypeName,
      options: carrierTypeNameOptions,
    },
    {
      text: 'Product Name',
      type: 'select',
      styled: 'true',
      handleChange: (e) => setProductName(e.target.value),
      value: productName,
      options: consumableOptions,
    },
    {
      text: 'Max Amount',
      type: 'number',
      handleChange: (e) => setMaxAmount(e.target.value),
      value: maxAmount,
    },
    {
      text: 'Min Amount',
      type: 'number',
      handleChange: (e) => setMinAmount(e.target.value),
      value: minAmount,
    },
    {
      text: 'Refill Amount',
      type: 'number',
      handleChange: (e) => setRefillAmount(e.target.value),
      value: refillAmount,
    }
  ];

  return (
    <DynamicForm
      title="Add New Carrier Contents"
      questions={questions}
      handleSubmit={handleSubmit}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      selectedId={selectedCarrierTypeId}
    />
  );
};

export default CarrierContentsForm;