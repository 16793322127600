import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import StyledDropdown from '../Styling components/StyledDropdown';
import Textinput from '../Styling components/Textinput';


const NSMedicineUpload = ({ token, onReload }) => {
    const [pouchNumber, setPouchNumber] = useState('');
    const pouchType = useSelector((state) => state.user.pouchType);
    const userBarcode = useSelector((state) => state.user.userBarcode);
    const stationBarcode = useSelector((state) => state.user.station_barcode);
    const pouchBarcode = useSelector((state) => state.user.pouchBarcode);
    const name = useSelector((state) => state.user.name)
    const [medicineBarcode, setMedicineBarcode] = useState('');
    const [medicineName, setMedicineName] = useState('');
    const [amount, setAmount] = useState('');
    const pouch_barcode = pouchBarcode;
    const user_barcode = userBarcode;
    const [remainingAmount, setRemainingAmount] = useState(null);
    const [medicineExists, setMedicineExists] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [forceAdd, setForceAdd] = useState(false);
    const [pouchAmount, setPouchAmount] = useState(null);
    const [stockAmount, setStockAmount] = useState(null);
    const [isStockAvailable, setIsStockAvailable] = useState(true);
    const [dataChanged, setDataChanged] = useState(false);
    const [medicines, setMedicines] = useState([]);
    const [selectedBatchNumber, setSelectedBatchNumber] = useState('');
    const [uniqueId, setUniqueId] = useState(''); 
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedValue, setSelectedValue] = useState("");


    useEffect(() => {
      const fetchMedicines = async () => {
        try {
          const response = await axios.get('/api/Consumable/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
            setMedicines(response.data);

          }
        } catch (error) {
          console.error('Error fetching medicines:', error);
        }
      };

    
      fetchMedicines();
    }, [token]);
    
    const handleSelectChange = async (e) => {
      const selectedUniqueId = Number(e.target.value); // converting string to number

      setUniqueId(selectedUniqueId);
      setSelectedValue(selectedUniqueId);
      const selectedMedicine = medicines.find(medicine => {
        return medicine.unique_id === selectedUniqueId; // Now it should work
      });
    
  
      if (selectedMedicine) {
        setMedicineName(selectedMedicine.product_name);
        setSelectedBatchNumber(selectedMedicine.batch_number);
        setUniqueId(selectedMedicine.unique_id);
        setMedicineBarcode(selectedMedicine.product_barcode);  // Added this line
    
        // Here we're using drug_barcode in our API call instead of uniqueId
        const response = await axios.get(`/api/Consumable_barcode/${selectedMedicine.product_barcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.length > 0) {
          setMedicineBarcode(response.data[0].product_barcode);
        }
      }
    };
    
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
    
  const filteredMedicines = searchTerm
  ? medicines.filter(medicine =>
      medicine.Product_Name && medicine.Product_Name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : medicines;



useEffect(() => {
    const fetchStockAmount = async () => {
      try {
        const response = await axios.get(`/api/stores_levels/${medicineBarcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data[0].Total_Amount) {
          setStockAmount(response.data[0].Total_Amount);
          setIsStockAvailable(true);
        } else {
          setStockAmount(0);
          setIsStockAvailable(false);
        }
      } catch (error) {
        console.error('Error fetching stock amount:', error);
      }
    };
  
    if (medicineBarcode) {
      fetchStockAmount();
    } else {
      setStockAmount(null);
    }
  }, [medicineBarcode, token, dataChanged]);
  

  useEffect(() => {
    const fetchRemainingAmount = async () => {
      try {
        const response = await axios.get(
          `/api/carrier_remaining_data/${pouch_barcode}/${pouchType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.length > 0) {
          const medicineData = response.data.find(
            (item) => item.Product_Name === medicineName
          );
          if (medicineData) {
            setRemainingAmount(medicineData.Remaining_Amount === null ? 0 : medicineData.Remaining_Amount);
            setMedicineExists(true);
          } else {
            setRemainingAmount(null);
            setMedicineExists(false);
          }
        } else {
          setRemainingAmount(null);
          setMedicineExists(false);
        }
        
      } catch (error) {
        console.error('Error fetching remaining amount:', error);
      }
    };
  
    if (medicineName) {
      fetchRemainingAmount();
    } else {
      setRemainingAmount(null);
    }
  }, [medicineName, pouchNumber, pouchType, token, dataChanged]);

  useEffect(() => {
    const fetchPouchNumber = async () => {
      try {
        const response = await axios.get(`/api/Carrier/${pouch_barcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.pouch_number) {
          setPouchNumber(response.data.pouch_number);
          localStorage.setItem('pouch_type', response.data.pouch_type);
        } else {
          setPouchNumber('');
        }
      } catch (error) {
        console.error('Error fetching pouch number:', error);
      }
    };

    if (pouch_barcode) {
      fetchPouchNumber();
    } else {
      setPouchNumber('');
    }
  }, [pouch_barcode, token, dataChanged]);


  useEffect(() => {
    const fetchPouchAmount = async () => {
      try {
        const response = await axios.get(`/api/carrier_contents_from_carrier_history_product/${pouchBarcode}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const pouchContent = response.data.find(item => item.Product_Name === medicineBarcode);
  
        if (pouchContent) {
          setPouchAmount(pouchContent.Total_Amount);
        } else {
          setPouchAmount(null);
        }
      } catch (error) {
        console.error('Error fetching pouch amount:', error);
      }
    };
  
    fetchPouchAmount();
  }, [pouchBarcode, token, dataChanged]);
  

  const isExpired = (expiryDate) => {
    const currentDate = new Date();
    const parsedExpiryDate = new Date(expiryDate);
    return parsedExpiryDate <= currentDate;
  };
  
  const isExpiringSoon = (expiryDate) => {
    const currentDate = new Date();
    const oneMonthLater = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const parsedExpiryDate = new Date(expiryDate);
    return parsedExpiryDate <= oneMonthLater;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch the selected medicine details
    const selectedMedicine = medicines.find(medicine => medicine.unique_id === uniqueId);
  
    // Check if the medicine is expired
    if (selectedMedicine && isExpired(selectedMedicine.expiry_date)) {
      alert('This product is expired. Please pick another batch.');
      // Clear the form
      resetForm();
      return;
    }
  
    // Check if the medicine is soon to expire
    if (selectedMedicine && isExpiringSoon(selectedMedicine.expiry_date)) {
      const userConfirmed = window.confirm('This product is soon to expire. Are you sure you want to add it?');
      if (!userConfirmed) {
        // Clear the form
        resetForm();
        return;
      }
    }

    setDataChanged(false);


    if (!isStockAvailable) {
      alert('Not in stock');
      return;
    }

    if (!medicineExists && !forceAdd) {
      setErrorMessage('Wrong drug. Do you want to add anyway?');
      setShowError(true);
      return;
    }

    if (amount > remainingAmount && !forceAdd) {
      setErrorMessage('Too many added. Do you want to add anyway?');
      setShowError(true);
      return;
    }

    try {
      for (let i = 0; i < amount; i++) {
        await axios.post('/api/Carrier_history/', {
          station_barcode: stationBarcode,
          user_packed: user_barcode,
          product_barcode: medicineBarcode,
          carrier_barcode: pouchBarcode,
          amount: 1,
          datetime_packed: new Date().toISOString(),
          status: 'Packed',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      await axios.post('/api/StockHoldingHistory/create/', {
        location: stationBarcode,
        user_barcode: user_barcode,
        product_barcode: medicineBarcode,
        transaction: 'Stock to Pouch',
        amount: amount,
        datetime_transaction: new Date().toISOString(),
        comments: `Packed by ${name}`,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert('Medicine uploaded successfully');
      setDataChanged(true);
      setForceAdd(false); 
      onReload();
    
      // Clear the form
      setMedicineBarcode('');
      setMedicineName('');
      setAmount('');
      setSelectedBatchNumber('');
      setUniqueId(''); 
      setSearchTerm('');
      setSelectedValue("");
    
    } catch (error) {
      console.error('Error uploading medicine:', error);
      alert('Error uploading medicine');
    }
};

  const handleAddAnyway = () => {
    setForceAdd(true);
    setShowError(false);
  };
  
  const handleDontAdd = () => {
    setForceAdd(false);
    setShowError(false);
  };
  

  const questions = [
    {
      type: "text",
      value: searchTerm,
      handleChange: handleSearchChange,
      text: "Search medicine name..."
    },
    {
      type: "number",
      value: amount,
      handleChange: (e) => setAmount(e.target.value),
      text: "Amount:"
    }
    //... Add more text inputs as needed
  ];
  
  const resetForm = () => {
    // Clear the form
    setMedicineBarcode('');
    setMedicineName('');
    setAmount('');
    setSelectedBatchNumber('');
    setUniqueId(''); 
    setSearchTerm('');
    setSelectedValue("");
  };

  return (
    <div className="medicine-upload-card">
      <div className="medicine-upload-header">
        <h2>Medicine Upload</h2>
      </div>
      <div className="medicine-upload-body">
        <Textinput questions={[questions[0]]} />
  
        <form onSubmit={handleSubmit} className="medicine-upload-form">
          <div className="medicine-upload-item">
            <label htmlFor="medicineName" className="medicine-upload-label">
              Medicine Name and Batch Number:
            </label>
            <div className="medicine-selection">
              <StyledDropdown
                options={filteredMedicines.map(medicine => ({
                  label: `${medicine.product_name} - ${medicine.batch_number}`,
                  value: medicine.unique_id
                }))}
                value={uniqueId || ''}
                onChange={handleSelectChange}
                placeholder="Select Medicine"
              />
            </div>
          </div>
  
          <div className="medicine-upload-item">
            <label htmlFor="amount" className="medicine-upload-label">
            </label>
            <div className="amount-input">
              <Textinput questions={[questions[1]]} />
            </div>
          </div>
  
          {showError && errorMessage && (
            <div className="medicine-upload-card">
              <div className="error-modal">
                <p>{errorMessage}</p>
                <button onClick={handleAddAnyway}>Add anyway</button>
                <button onClick={handleDontAdd}>Don't add</button>
              </div>
            </div>
          )}
  
          {stockAmount !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Stock Amount:</span>
              <span className="medicine-upload-value">{stockAmount}</span>
            </div>
          )}
  
          {!isStockAvailable && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-error">Not in stock</span>
            </div>
          )}
  
          {pouchAmount !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Amount in Pouch:</span>
              <span className="medicine-upload-value">{pouchAmount}</span>
            </div>
          )}
  
          {pouchNumber !== null && (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Pouch Number:</span>
              <span className="medicine-upload-value">{pouchNumber}</span>
            </div>
          )}
  
          {medicineName && (remainingAmount !== null ? (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Amount Remaining:</span>
              <span className="medicine-upload-value">{remainingAmount}</span>
            </div>
          ) : (
            <div className="medicine-upload-item">
              <span className="medicine-upload-label">Incorrect Drug:</span>
              <span className="medicine-upload-value">Not in the pouch type</span>
            </div>
          ))}
  
          <div className="medicine-upload-item">
            <button className="medicine-upload-button" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
  
};

export default NSMedicineUpload;